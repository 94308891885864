import Header from './components/Header';
import Footer from './components/Footer';
import Techstack from './components/Techstack';
import Cta from './components/Cta';
import Home from './pages/Home';
import Minesweeper from './pages/Minesweeper';
import './App.css'; 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="main">
          <Header />
            <div className="wrap">
              <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='/Minesweeper' element={<Minesweeper />} />
              </Routes>
              <Techstack />
              <Cta />
            </div>
          <Footer />
      </div>
    </Router>
    
  );
}

export default App;