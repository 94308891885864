import IconSparkle from './../sparkle.svg';
import ThumbnailMineseweper from './../minesweeper.png';
import ThumbnailExpertEcho from './../expertecho.png';

function Home() {
  return (
    <div className='home'>
        <div className='left'>
            <div className='top'>
                <div className='face'>
                    <div className='face-available'></div>
                </div>
                <h1>Jeff Liao</h1>
                <div className='profession'>Web Developer</div>
            </div>
            <div className='bottom'>
                <div className='history'>
                    <WorkHistory jobtitle='Wordpress Developer' company='Upwork' year='2018 - Present' />
                    <WorkHistory jobtitle='Full Stack Web Developer' company='Independent Contractor' year='2016 - 2018' />
                    <WorkHistory jobtitle='Web Developer' company='Independent Contractor' year='2015 - 2016' />
                </div>
                <div className='skills'>
                    <SkillLine direction='right' skills={['Responsive Design','Problem Solving','WordPress Expert']} />
                    <SkillLine direction='left' skills={['Responsive Design','Problem Solving','WordPress Expert']} />
                    <SkillLine direction='right' skills={['Responsive Design','Problem Solving','WordPress Expert']} />
                </div>
             </div>
        </div>
        <div className='right'>
            <h3>Portfolio</h3>
            <h4>Recent work for several clients</h4>
            <div className='work-list'>
                <Work title='Minesweeper' thumbnail={ThumbnailMineseweper} tags={['Application', 'ReactJS']} />
                <Work title='Expert Echo Website' thumbnail={ThumbnailExpertEcho} tags={['Web Dev', 'WordPress', 'ACF']} />
                <Work title='Minesweeper' thumbnail={ThumbnailMineseweper} tags={['Application', 'ReactJS']} />
                <Work title='Expert Echo Website' thumbnail={ThumbnailExpertEcho} tags={['Web Dev', 'WordPress', 'ACF']} />
            </div>
        </div>
    </div>
  );
}

function WorkHistory({ jobtitle, company, year }) {
    return (
        <div className='work'>
            <div className='jobtitle'>{jobtitle}</div>
            <div className='company'>{company}</div>
            <div className='year'>{year}</div>
        </div>
    );
}

function Skills({ skills }) {
    return (
        <div className='skillfeed'>
            {skills.map((skill, index) => (
                <div className='skillpair'>
                    <img src={IconSparkle} alt='Icon Sparkle' />
                    <div className='skill'>
                        {skill}
                    </div>
                </div>             
            ))}
        </div>
    );
}

function SkillLine({ direction, skills }) {
    return (
        <div className={ `skillsline direction-${direction}` }>
            <div class='carousel-one'>
                <Skills skills={skills} />
            </div>
            <div class='carousel-two'>
                <Skills skills={skills} />
            </div>
        </div>
    );
}

function Work({ title, thumbnail, tags }) {
    return (
        <div className='work'>
            <div className='thumbnail'>
                <img src={thumbnail} alt={title} />
            </div>
            <div className='tags'>
                {tags.map((tag, index) => (
                    <div className='tag'>
                        {tag}
                    </div>      
                ))}
            </div>
            <div className='title'>
                {title}
            </div>
        </div>
    )
}

export default Home;