import LogoGithub from './../logo_github.svg';
import LogoTwitter from './../logo_twitter.svg';
import LogoLinkedIn from './../logo_linkedin.svg';
import LogoYoutube from './../logo_youtube.svg';

function Footer() {
    return (
      <footer>
        <div className='wrap'>
            <div className='left'>
                &copy; jmliao.com {new Date().getFullYear()} All Rights Reserved
            </div>
            <div className='right'>
                <a href='https://github.com/geethebunny'>
                    <img src={LogoGithub} alt='Github' />
                </a>
                <a href='https://twitter.com/geethebunny'>
                    <img src={LogoTwitter} alt='Twitter' />
                </a>
                <a href='https://www.linkedin.com/in/jeff-monico-liao/'>
                    <img src={LogoLinkedIn} alt='LinkedIn' />
                </a>
                <a href='https://www.youtube.com/@geethebunny'>
                    <img src={LogoYoutube} alt='Youtube' />
                </a>
            </div>
        </div>
      </footer>
    );
}
  
export default Footer;