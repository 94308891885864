function Cta() {
    return (
      <div className='cta'>
        <div className='top'>
            Available for Work!
        </div>
        <div className='middle'>
            Need help with your project?
        </div>
        <div className='bottom'>
            Send me an email and I'll get back to you within 24 hours.
        </div>
        <a href='mailto:themonkeygee@gmail.com'>Contact Me</a>
      </div>
    );
}
  
export default Cta;