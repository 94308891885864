import LogoFigma from './../logo_figma.svg';
import LogoPhotoshop from './../logo_photoshop.svg';
import LogoXd from './../logo_adobexd.svg';
import LogoHtml from './../logo_html5.svg';
import LogoCss from './../logo_css3.svg';
import LogoVscode from './../logo_vscode.svg';

function Techstack() {
    return (
      <div className='techstack'>
        <Tech logo={LogoFigma} alt='Figma' />
        <Tech logo={LogoPhotoshop} alt='Photoshop' />
        <Tech logo={LogoXd} alt='Adobe XD' />
        <Tech logo={LogoHtml} alt='HTML5' />
        <Tech logo={LogoCss} alt='CSS3' />
        <Tech logo={LogoVscode} alt='VS Code' />
      </div>
    );
}

function Tech({ logo, alt }) {
    return (
        <div className='tech'>
            <div className='logo'>
                <img src={logo} alt={alt} />
            </div>
        </div>
    );
}
  
export default Techstack;